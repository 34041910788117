<template>
<div class="home">
  <div class="container">
    <div class="row row-split-content">
      <div class="content-width-medium">
        <h1>{{ $auth.user.name }}</h1>
        <div class="form-wrapper margin-top w-form">

<div class="button-row">
          <button class="button logout" v-on:click="logout">Log Out</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
 
<script>
export default {
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    }
  }
}
</script> 
<style scoped>
.logout {
  margin-left: 15px;
  background-color: #f19953;
  border-color: #f19953;
}
.cancel {
  margin-left: 15px;
  background-color: #ed6a5e;
  border-color: #ed6a5e;
}
.button-row
{
  margin-bottom: 25px;
}
</style>